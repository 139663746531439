import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

const Select = ({ className, onChange, value, options }) => {
    const [selectedVal, setSelectedVal] = useState(null);
    const select = (assigned) => {
        if (onChange) {
            onChange(assigned);
        }
        setSelectedVal(assigned);
    };
    useEffect(() => {
        if (value !== selectedVal) {
            select(value);
        }
    }, [value]);
    return (
        <div
            className={`select order-delivery__select select_stat ${className}`}
        >
            <div className="select__container">
                <div className="select__curr closed">
                    {value?.length ? value : 'Stat'}
                </div>
                <ul className="select__list">
                    {options.map((el, key) => {
                        return (
                            <li
                                key={key}
                                className="select__item"
                                onClick={() => select(el)}
                            >
                                {el}
                            </li>
                        );
                    })}
                </ul>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    options: state.basicReducer.states ?? ['BG'],
});

export default connect(mapStateToProps, null)(Select);

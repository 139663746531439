import axios from 'axios';

const setAuthenticated = (user, authenticated, loading) => {
    return {
        type: 'GET_USER',
        payload: {
            user: user,
            authenticated,
            loading: loading,
        },
    };
};

const innerAuthenticate = async (url, credentials, dispatch) => {
    await dispatch(setAuthenticated({}, false, true));
    try {
        let res = await axios.post(url, credentials).catch(global.handleError);
        if (undefined !== res) {
            const user = res?.data?.user;
            if (user) {
                user.token = res?.data?.token;
                await localStorage.setItem('user', JSON.stringify(user));
                global.locale = user.lang == 0 ? 'bg' : 'en';
                await dispatch(setAuthenticated(user, true, false));
                return user;
            } else {
                await dispatch(setAuthenticated(null, false, false));
                return true;
            }
        } else {
            await dispatch(setAuthenticated(null, false, false));
        }
        return false;
    } catch (error) {
        await dispatch(setAuthenticated(null, false, false));
        global.Alert(error.toString());
        console.error(error, 'Error while authenticating');
        return false;
    }
};

const authenticate = (credentials = {}) => {
    return async (dispatch) => {
        const authUrl = `${global.API_ADDRESS}/api/login`;
        return await innerAuthenticate(authUrl, credentials, dispatch);
    };
};

const register = (credentials = {}) => {
    return async (dispatch) => {
        const authUrl = `${global.API_ADDRESS}/api/register`;
        return await innerAuthenticate(authUrl, credentials, dispatch);
    };
};

const logOut = () => {
    return async (dispatch, getState) => {
        await dispatch(setAuthenticated(null, false, false));
        // await localStorage.removeItem('user');
        // localStorage.clear();
        // await dispatch({ type: 'LOG_OUT' });
    };
};

export const handleUserCheckedTerms = () => {
    return async (dispatch) => {
        const url = `${global.API_ADDRESS}/api/userCheckedTerms`;
        let header = await global.getHeader();
        let res = await axios.get(url, header).catch(async (error) => {
            console.log(error);
            global.Alert(error.toString());
        });
        return res?.data?.status;
    };
};

export default { authenticate, register, logOut };

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import modalActions from '../../redux/actions/Modal';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

const Footer = (props) => {
    const {
        modalShown,
        actions,
        termsShown,
        errorMessage,
        message,
        confirm,
        confirmAction,
    } = props;
    const [orderInProcess, setOrderInProcess] = useState(false);
    const submitInner = () => {
        setOrderInProcess(true);
        confirmAction();
        setTimeout(() => {
            setOrderInProcess(false);
        }, 15000);
    };
    const toggle = () => {
        actions.toggleModal();
    };
    return (
        <div>
            {errorMessage?.length || message?.length || confirm?.length ? (
                <div className="modal-mask"></div>
            ) : null}
            {errorMessage?.length ? (
                <div className="message-alert">
                    <div
                        className="message-alert__close"
                        onClick={() => actions.setErrorMessage('')}
                    ></div>
                    <div className="message-alert__title">
                        {global._e('Error')}
                    </div>
                    <div className="message-alert__descr">{errorMessage}</div>
                </div>
            ) : null}
            {confirm?.length ? (
                <div className="message-alert">
                    <div
                        className="message-alert__close"
                        onClick={() => actions.setConfirm('')}
                    ></div>
                    <div className="message-alert__title">
                        {global._e('Warning')}
                    </div>
                    <div className="message-alert__descr">{confirm}</div>
                    <button
                        className="button button--solid order-action__submit mt-2"
                        onClick={orderInProcess ? () => {} : submitInner}
                        disabled={orderInProcess}
                    >
                        {global._e('Confirm')}
                    </button>
                </div>
            ) : null}
            {message?.length ? (
                <div className="message-alert message-alert-success">
                    <div
                        className="message-alert__close"
                        onClick={() => actions.setMessage('')}
                    ></div>
                    <div className="message-alert__title"></div>
                    <div className="message-alert__descr">{message}</div>
                </div>
            ) : null}
            <footer className="footer">
                <div className="container">
                    <Link to="/" className="footer__logo">
                        <img
                            src="./assets/img/footer/logo.png"
                            alt="footer-logo"
                        />
                    </Link>
                    <div className="footer__copyright">
                         © {new Date().getFullYear()} {termsShown}
                        {_e('Все права защищены')}.
                    </div>
                </div>
            </footer>

            <div
                className={`${
                    modalShown === true
                        ? 'modal-contacts modal-contacts__is-visible'
                        : 'modal-contacts'
                }`}
            >
                <button
                    onClick={toggle}
                    className="modal-contacts__overlay modal-contacts__toggle"
                ></button>
                <div className="modal-contacts__wrapper modal-contacts__transition">
                    <div className="modal-contacts__body">
                        <button
                            onClick={toggle}
                            className="modal-contacts__close modal-contacts__toggle"
                        ></button>
                        <div className="modal-contacts__phones">
                            <a
                                href="tel:+420776123094"
                                className="modal-contacts__phone"
                            >
                                +420 <span>776-123-094</span>
                            </a>
                            <a
                                href="tel:+420771166507"
                                className="modal-contacts__phone"
                            >
                                +420 <span>771-166-507</span>
                            </a>
                        </div>
                        {/*<div className="modal-contacts__massagers">*/}
                        {/*    <div className="modal-contacts__massager">*/}
                        {/*        <div className="modal-contacts__massager-icon">*/}
                        {/*            <img*/}
                        {/*                src="./assets/img/icons/whatsapp.png"*/}
                        {/*                alt="whatsapp"*/}
                        {/*            />*/}
                        {/*        </div>*/}
                        {/*        <div className="modal-contacts__massager-title">*/}
                        {/*            @lorelli_cz*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*    <div className="modal-contacts__massager">*/}
                        {/*        <div className="modal-contacts__massager-icon">*/}
                        {/*            <img*/}
                        {/*                src="./assets/img/icons/telega.png"*/}
                        {/*                alt="telega"*/}
                        {/*            />*/}
                        {/*        </div>*/}
                        {/*        <div className="modal-contacts__massager-title">*/}
                        {/*            lorelli_cz*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*    <div className="modal-contacts__massager">*/}
                        {/*        <div className="modal-contacts__massager-icon">*/}
                        {/*            <img*/}
                        {/*                src="./assets/img/icons/viber.png"*/}
                        {/*                alt="viber"*/}
                        {/*            />*/}
                        {/*        </div>*/}
                        {/*        <div className="modal-contacts__massager-title">*/}
                        {/*            lorelli_cz*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <div className="modal-contacts__mails">
                            <a
                                target="_blank"
                                href="mailto:obchod@dashandel.cz"
                                className="modal-contacts__mail"
                            >
                                obchod@dashandel.cz
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className={`${
                    termsShown === true
                        ? 'modal-contacts modal-contacts__is-visible'
                        : 'modal-contacts'
                }`}
            >
                <button
                    onClick={actions.toggleTermsModal}
                    className="modal-contacts__overlay modal-contacts__toggle"
                ></button>
                <div className="modal-contacts__wrapper modal-contacts__transition modal-terms">
                    <div className="modal-contacts__body">
                        <button
                            onClick={actions.toggleTermsModal}
                            className="modal-contacts__close modal-contacts__toggle"
                        ></button>
                        <div className="modal-contacts__phones">
                            <iframe
                                src={
                                    props.terms ??
                                    'https://b2b.parnici.bg/obhodnij.html'
                                }
                                frameBorder="0"
                            ></iframe>
                        </div>
                    </div>
                </div>
            </div>
            <div className="gallery-mask">
                <img
                    className="gallery-close-icon"
                    src="/assets/img/close.svg"
                    alt=""
                />
                <img
                    className="gallery-next-icon"
                    src="/assets/img/right-arrow.svg"
                    alt=""
                />
                <img
                    className="gallery-prev-icon"
                    src="/assets/img/left-arrow.svg"
                    alt=""
                />
                <div className="gallery-img-wrapper">
                    <img
                        src="https://dealer.dashandel.cz/images/a72deb5d-a16e-4f57-b9fc-ce3a7d261663.jpg"
                        alt=""
                    />
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    modalShown: state.modalReducer.callModal,
    termsShown: state.modalReducer.termsModal,
    errorMessage: state.modalReducer.errorMessage,
    message: state.modalReducer.message,
    confirm: state.modalReducer.confirm,
    confirmAction: state.modalReducer.confirmAction,
    terms: state.staticReducer.terms,
});

const ActionCreators = Object.assign({}, modalActions);
const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(ActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Footer);

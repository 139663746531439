import React, { useState, useEffect, useRef } from 'react';
import basicActions from '../../redux/actions/Basic';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';

function Screen(props) {
    const {
        cartFull,
        orderedProducts,
        basicInfo,
        totalPrices,
        totalCount,
    } = props;
    const [deliveryThreshold, setDeliveryThreshold] = useState(null);
    const [deliveryFree, setDeliveryFree] = useState(null);
    const [deliveryPrice, setDeliveryPrice] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);
    useEffect(() => {
        const deliveryUntil =
            basicInfo?.delivery_min?.[global.locale] -
            totalPrices?.[global.locale];
        if (deliveryUntil < 0) {
            setDeliveryFree(true);
        } else {
            setDeliveryFree(false);
        }
        setTotalPrice(totalPrices?.[global.locale]);
        setDeliveryPrice(basicInfo?.delivery_price?.[global.locale]);
        setDeliveryThreshold(deliveryUntil);
    }, [basicInfo, orderedProducts, totalPrices]);
    // const [scroll, setScroll] = useState(false);
    // const handleScroll = () => {
    // console.log(
    //     footerRef?.current?.getBoundingClientRect()?.y,
    //     footerRef?.current?.offsetTop,
    //     window.scrollY
    // );
    // const scrollCheck =
    //     footerRef?.current?.getBoundingClientRect()?.y >= window.scrollY;
    // setScroll(scrollCheck);
    // };
    // useEffect(() => {
    //     document.addEventListener('scroll', handleScroll, { passive: true });
    //     return () => document.removeEventListener('scroll', handleScroll);
    // }, []);

    return (
        <>
            {cartFull ? (
                <div className="products-footer">
                    <div className="container">
                        <div className="products-footer__left">
                            <div className="products-footer-order">
                                <div className="products-footer-order__icon">
                                    <img
                                        src="../assets/img/icons/cart.png"
                                        alt="cart"
                                    />
                                </div>
                                <div className="products-footer-order__info">
                                    {global._e('V košíku')}{' '}
                                    <span className="products-footer-order__icon-count">
                                        {totalCount}
                                    </span>{' '}
                                    {totalCount > 1
                                        ? global._e('Celková částka je')
                                        : global._e('Celková cena je')}{' '}
                                    <span className="products-footer-order__icon-cost">
                                        {global.nf(totalPrice)}
                                        {global._e('currency_alt')}
                                    </span>
                                </div>
                                <Link
                                    to={{
                                        pathname: '/step_0',
                                        state: { fromDashboard: true },
                                    }}
                                    className="products-footer-order__btn"
                                >
                                    {global._e('Zkontrolovat')}
                                </Link>
                            </div>
                        </div>
                        <div className="products-footer__right">
                            <div className="products-footer__delivery">
                                {deliveryFree === null
                                    ? ''
                                    : deliveryFree === true
                                    ? `${global._e(
                                          'Cena za doručení:'
                                      )} ${global._e('zdarma')}`
                                    : `${global._e(
                                          'Cena za doručení:'
                                      )} ${global.nf(
                                          deliveryPrice
                                      )} ${global._e(
                                          'currency_alt'
                                      )} (${global._e(
                                          'For free delivery order more than'
                                      )} ${global.nf(
                                          deliveryThreshold
                                      )} ${global._e('currency_alt')})`}
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}
        </>
    );
}

const ActionCreators = Object.assign({}, basicActions);
const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(ActionCreators, dispatch),
});
const mapStateToProps = (state) => ({
    cartFull: state.orderReducer.cartFull,
    basicInfo: state.basicReducer,
    products: state.productReducer.products,
    orderedProducts: state.orderReducer.products,
    totalCount: state.orderReducer.totalCount,
    totalPrices: state.orderReducer.totalPrices,
});

export default connect(mapStateToProps, mapDispatchToProps)(Screen);

import React, { useEffect } from 'react';
// import { useHistory } from 'react-router-dom';
import WithAuth from '../hocs/WithAuth';

function Screen() {
    // let history = useHistory();

    useEffect(() => {
        window.location = '/';
    }, []);
    return (
        <div>
            <h1 className="text-center mt-5 pt-5">Not Authenticated</h1>
            <a href="/">Log In</a>
        </div>
    );
}

export default WithAuth(Screen);

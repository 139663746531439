import React, { useEffect } from 'react';
// import { useHistory } from 'react-router-dom';

export default function withAuth(WrappedComponent) {
    return function Auth(props) {
        // const history = useHistory();

        useEffect(() => {
            // console.log('aa333333333333a')
            // window.location = '/';
            // history.push( '/not-authenticated' );
        }, []);

        return <WrappedComponent {...props} />;
    };
}

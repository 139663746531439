import React, { useState, useEffect } from 'react';
import { Link, useHistory, Redirect } from 'react-router-dom';
import { getOrders } from '../../actions/Order';
import Loader from '../../components/Loader';
import WithAuth from '../../hocs/WithAuth';
import authActions from '../../redux/actions/Auth';
import modalActions from '../../redux/actions/Modal';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

function Screen(props) {
    let history = useHistory();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const { actions, authenticated } = props;

    const login = async () => {
        if (!global.validateEmailField(email)) {
            actions.setErrorMessage(_e('Email error'));
            return;
        } else if (!global.validateField(password, 5, 50)) {
            actions.setErrorMessage(_e('Password error'));
            return;
        }
        const res = await actions.authenticate({
            // email: 'kocarkyvav@seznam.cz',
            // password: '572570918',
            email,
            password,
        });
        console.log(res);
    };
    useEffect(() => {
        // if ( false === productsInfo ) {
        //   actions.setErrorMessage( 'No products in cart' );
        //   return false;
        // }
        if (authenticated) {
            history.push('/');
        }
        document.title = _e('Log In');
    }, []);
    // CLIENT ADDRESS
    return (
        <>
            {authenticated ? (
                <Redirect to="/" />
            ) : (
                <main className="main">
                    <div className="container">
                        <div className="auth-block login">
                            <div className="auth-header__wrap">
                                <div className="auth-header">
                                    <div className="auth-header__icon">
                                        <img
                                            src="./assets/img/auth/login.png"
                                            alt="Login"
                                        />
                                    </div>
                                    <div className="auth-header__text">
                                        {_e('Login')}
                                    </div>
                                </div>
                            </div>
                            <div className="auth-form__wrap">
                                <form action="" className="auth-form">
                                    <div className="auth-form-block">
                                        <label
                                            htmlFor=""
                                            className="auth-form-block__label"
                                        >
                                            {_e('E-Mail Address')}
                                        </label>
                                        <input
                                            value={email}
                                            onChange={(e) =>
                                                setEmail(e.target.value)
                                            }
                                            type="email"
                                            className="auth-form-block__input"
                                        />
                                    </div>
                                    <div className="auth-form-block">
                                        <label
                                            htmlFor=""
                                            className="auth-form-block__label"
                                        >
                                            {_e('Password')}
                                        </label>
                                        <input
                                            value={password}
                                            onChange={(e) =>
                                                setPassword(e.target.value)
                                            }
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') {
                                                    login();
                                                }
                                            }}
                                            type="password"
                                            className="auth-form-block__input"
                                        />
                                    </div>
                                    {/*<div className="auth-form-checkbox auth-form-personal">*/}
                                    {/*    <div className="radio">*/}
                                    {/*        <label className="radio__label">*/}
                                    {/*            <input*/}
                                    {/*                className="radio__input"*/}
                                    {/*                type="checkbox"*/}
                                    {/*                checked={true}*/}
                                    {/*            />*/}
                                    {/*            <span className="radio__check"></span>*/}
                                    {/*            <span className="radio__title">*/}
                                    {/*                Запомнить меня на*/}
                                    {/*                этом компьютере*/}
                                    {/*            </span>*/}
                                    {/*        </label>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    <div className="auth-form-register__btn-wrap">
                                        <button
                                            type="button"
                                            onClick={login}
                                            className="auth-form-register__btn button button--solid"
                                        >
                                            {_e('Log In')}
                                        </button>
                                        <Link
                                            to="/register"
                                            className="auth-form-register__btn button button--o"
                                        >
                                            {_e('Register')}
                                        </Link>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </main>
            )}
        </>
    );
}

const mapStateToProps = (state) => ({
    authenticated: state.authReducer.authenticated,
});

const ActionCreators = Object.assign({}, authActions, modalActions);
const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(ActionCreators, dispatch),
});

// export default WithNavigation(MainScreen, true, false);
export default connect(mapStateToProps, mapDispatchToProps)(WithAuth(Screen));
